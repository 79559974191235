<template>
  <v-file-input
    ref="fileInput"
    :required="required"
    :value="file"
    :show-size="showSize"
    :placeholder="placeholder || 'Upload File'"
    :hint="hint"
    :persistent-hint="persistentHint"
    :disabled="readonly"
    :hideInput="hideInput"
    @change="handleFileChange"
  ></v-file-input>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { UPLOAD_FILE } from '@/store/modules/Attachment/actions';

export default {
  name: 'UploadFile',
  inject: ['eventHub'],
  components: {},
  props: {
    recordType: String,
    recordId: Number,
    showSize: Boolean,
    placeholder: Boolean,
    hint: String,
    persistentHint: Boolean,
    readonly: Boolean,
    required: {
      type: Boolean,
      default: false,
    },
    hideInput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      file: null,
    };
  },
  computed: {
    ...mapGetters('user', ['me']),
  },
  methods: {
    ...mapActions('attachment', [UPLOAD_FILE]),
    async handleFileChange(file) {
      this.file = file;
      // this.$emit('handleFile');
      this.upload();
    },
    async upload() {
      if (!this.file) return;
      this.$emit('onUpload', 'uploading');
      const reader = new FileReader();
      reader.onload = async (f) => {
        if (reader.result.length > 2e7) {
          this.$myalert.error('File is too large (20MB or smaller)');
          return;
        }
        const content = btoa(reader.result);
        const obj = {
          recordType: this.recordType,
          recordId: this.recordId,
          filename: this.file.name,
          type: this.file.type,
          comments: '',
          content,
        };
        try {
          const r = await this.uploadFile(obj);
          if (r && r.done) {
            this.file = null;
            this.$emit('onUpload');
            this.$myalert.success('File uploaded');
          }
        } catch (e) {
          this.$myalert.error(e.message);
          this.$emit('onUpload', false);
        }
      };
      reader.readAsBinaryString(this.file);
    },
    async deleteAttachment(item) {
      if (!this.readonly) {
        const yes = await this.$myconfirm('Do you want to delete this file? This operation cannot be undone');
        if (yes) {
          await this.deleteFile({ recordType: this.recordType, recordId: this.recordId });
          this.$myalert.success('File deleted');
        }
      }
    },
    mimicClick() {
      this.$refs.fileInput.$refs.input.click();
    },
  },
};
</script>

<style scoped></style>
