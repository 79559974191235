<template>
  <v-form :readonly="!canEditSettings">
    <v-row dense>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-btn fab small dark color="primary" @click="addDWR()" v-if="!editedDWR && canEditSettings">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn v-else v-show="editedDWR" fab small dark color="primary" @click="addDWR()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-scroll-x-transition>
          <v-btn v-show="selected.length" class="mx-2" dark color="red" @click="deleteDWR()" v-if="canEditSettings">
            <v-icon>mdi-delete</v-icon>Delete {{ selected.length }} District-Wide Rate{{
              selected.length > 1 ? 's' : ''
            }}
          </v-btn>
        </v-scroll-x-transition>
      </v-col>
    </v-row>
    <v-row dense v-if="editedDWR">
      <v-col cols="12" md="12">
        <v-card>
          <v-container>
            <v-row dense>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  label="Name"
                  :value="editedDWR.name"
                  v-model="$v.form.name.$model"
                  required
                  :error-descriptions="handleErrors($v.form.name)"
                  @blur="$v.form.name.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="4">
                <v-text-field
                  :value="editedDWR.rate"
                  v-model="$v.form.rate.$model"
                  label="Rate Per Hour"
                  type="number"
                  :min="0"
                  outlined
                  prepend-inner-icon="mdi-currency-usd"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :value="editedDWR.otRate"
                  v-model="$v.form.otRate.$model"
                  label="OT Rate Per Hour"
                  type="number"
                  :min="0"
                  outlined
                  prepend-inner-icon="mdi-currency-usd"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :value="editedDWR.otherRate"
                  v-model="$v.form.otherRate.$model"
                  label="Other Rate Per Hour"
                  type="number"
                  :min="0"
                  outlined
                  prepend-inner-icon="mdi-currency-usd"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="12">
                <v-btn color="green" dark @click="save" class="save-btn" v-if="canEditSettings"> save </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" md="12">
        <v-data-table
          @click:row="editTTM($event)"
          fixed-header
          :headers="headers"
          :items="districtWideRates"
          item-key="id"
          show-select
          v-model="selected"
        >
          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
            <v-simple-checkbox :value="isSelected" @input="select($event)" v-ripple></v-simple-checkbox>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { handleErrors } from '@/util';
import { mapActions, mapGetters } from 'vuex';
import {
  GET_DISTRICT_WIDE_RATES,
  SAVE_DISTRICT_WIDE_RATE,
  DELETE_DISTRICT_WIDE_RATES,
} from '@/store/modules/Config/actions';

const newDWR = {
  name: '',
  rate: 0,
  otRate: 0,
  otherRate: 0,
};

export default {
  mixins: [validationMixin],
  components: {},
  props: {},
  data: () => ({
    handleErrors,
    districtWideRates: [],
    id: null,
    form: newDWR,
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Rate / Hour', value: 'rate' },
      { text: 'OT Rate / Hour', value: 'otRate' },
      { text: 'Other Rate / Hour', value: 'otherRate' },
    ],
    editedDWR: null,
    selected: [],
  }),
  validations: {
    form: {
      name: { required },
      rate: {},
      otRate: {},
      otherRate: {},
    },
  },
  computed: {
    ...mapGetters('user', ['me', 'canEditSettings']),
  },
  async mounted() {
    await this.fetchItems();
  },
  methods: {
    ...mapActions('config', [GET_DISTRICT_WIDE_RATES, SAVE_DISTRICT_WIDE_RATE, DELETE_DISTRICT_WIDE_RATES]),
    async fetchItems() {
      this.districtWideRates = await this.getDistrictWideRates();
    },
    clear() {
      this.form = newDWR;
      this.$refs.aform.clear();
    },
    addDWR() {
      if (!this.editedDWR) this.editedDWR = { id: 0, ...newDWR };
      else this.editedDWR = null;
    },
    editTTM(ttm) {
      this.editedDWR = ttm;
    },
    async save() {
      if (this.$v.form.$invalid) {
        this.$myalert.error('Please complete all fields');
        return;
      }
      try {
        const r = await this.saveDistrictWideRate(this.$v.form.$model);
        if (r && r.id) {
          this.$myalert.success('District-Wide Rate saved');
          this.editedDWR = null;
          await this.fetchItems();
        }
      } catch (error) {
        this.$myalert.error(error.description);
      }
    },
    async deleteDWR() {
      const yes = await this.$myconfirm(
        `Are you sure you want to delete ${
          this.selected.length > 1 ? 'these District-Wide Rates' : 'this District-Wide Rate'
        }? This operation cannot be undone.`
      );
      if (yes) {
        const r = await this.deleteDistrictWideRates(this.selected.map((e) => e.id));
        if (r.done) {
          this.$myalert.success(`${this.selected.length > 1 ? 'District-Wide Rates' : 'District-Wide Rate'} deleted`);
          this.editedDWR = null;
          this.selected = [];
          this.fetchItems();
        }
      }
    },
  },
  watch: {
    editedDWR: {
      immediate: true,
      deep: true,
      handler: function (editedDWR) {
        this.form = { ...newDWR, ...editedDWR };
      },
    },
  },
};
</script>

<style scoped>
.checkbox {
  display: flex;
  align-items: center !important;
}
</style>
