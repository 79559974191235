<template>
  <v-form :readonly="!canEditSettings">
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-btn v-if="canEditSettings" text class="nav-button success white--text mb-4" @click="save">
          <v-icon left class="white--text">mdi-content-save</v-icon>
          Save
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="px-2" dense>
      <v-col cols="12" md="4">
        <v-subheader>Display Additional Invoice Costs on Invoice</v-subheader>
        <v-radio-group v-model="displayAdditionalInvoiceCostsInInvoice" row>
          <v-radio label="Yes" :value="true"></v-radio>
          <v-radio label="No" :value="false"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_CONFIG, SET_CONFIG } from '@/store/modules/Config/actions';

export default {
  inject: ['eventHub'],
  data: () => ({
    displayAdditionalInvoiceCostsInInvoice: false,
  }),
  computed: {
    ...mapGetters('user', ['me', 'canEditSettings']),
  },
  async mounted() {
    await this.fetchItems();
  },
  methods: {
    ...mapActions('config', [GET_CONFIG, SET_CONFIG]),
    async fetchItems() {
      this.iConfig = await this.getConfig('invoice');

      if (this.iConfig && this.iConfig.displayAdditionalInvoiceCostsInInvoice)
        this.displayAdditionalInvoiceCostsInInvoice = this.iConfig.displayAdditionalInvoiceCostsInInvoice;
    },
    async save() {
      try {
        const value = this.iConfig
          ? { ...this.iConfig, displayAdditionalInvoiceCostsInInvoice: this.displayAdditionalInvoiceCostsInInvoice }
          : { displayAdditionalInvoiceCostsInInvoice: this.displayAdditionalInvoiceCostsInInvoice };
        const response = await this.setConfig({ name: 'invoice', value });
        if (response && response.done) {
          this.$myalert.success('Additional Invoice Cost settings saved');
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
  },
};
</script>

<style scoped>
.save-btn {
  float: right;
  margin-bottom: 12px;
}
.inline {
  display: inline-block;
}
</style>
