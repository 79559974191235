<template>
  <v-container fluid>
    <v-expansion-panels mandatory>
      <v-expansion-panel>
        <v-expansion-panel-header> <div class="header-text">Settings</div> </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn v-if="canEditSettings" text class="nav-button success white--text mb-4" @click="save">
                <v-icon left class="white--text">mdi-content-save</v-icon>
                Save
              </v-btn>
            </v-col>
          </v-row>
          <v-form :readonly="!canEditSettings">
            <v-row class="px-2" dense>
              <v-col cols="12" md="4" class="bg-blue">
                <v-subheader>Display Trip Estimator?</v-subheader>
                <v-radio-group v-model="tripEstimate.display" row>
                  <v-radio label="Yes" :value="true"></v-radio>
                  <v-radio label="No" :value="false"></v-radio>
                </v-radio-group>
                <v-subheader>Include Additional Invoice Cost on Trip Estimate?</v-subheader>
                <v-radio-group v-model="tripEstimate.showAdditionalInvoiceCost" row>
                  <v-radio label="Yes" :value="true"></v-radio>
                  <v-radio label="No" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="4" class="pt-4">
                <v-text-field
                  v-model="tripEstimate.substituteCost"
                  label="Amount used per substitute teacher for estimating trip cost"
                  type="number"
                  min="0"
                  outlined
                  prepend-inner-icon="mdi-currency-usd"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="bg-blue">
                <v-subheader>Select the rate to be used as the default on the Trip Estimator</v-subheader>
                <v-radio-group v-model="tripEstimate.defaultRate" row>
                  <v-radio v-for="(dwr, i) in districtWideRates" :key="i" :label="dwr.name" :value="dwr.id"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="header-text">District-Wide Rates</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <district-wide-rates></district-wide-rates>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="header-text">Fuel Prices</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <fuel-prices></fuel-prices>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="false">
        <v-expansion-panel-header>
          <div class="header-text">Additional Invoice Costs</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <additional-invoice-costs></additional-invoice-costs>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="header-text">Trip Estimate Template</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="6" v-if="!hasTemplate">
              <upload-file recordType="tripestimate" :recordId="1" showSize @onUpload="onUpload"></upload-file>
            </v-col>
            <v-col cols="12">
              <attachments
                ref="attachments"
                recordType="tripestimate"
                :recordId="1"
                @onFiles="handleOnFile"
                @onFileDelete="refreshStore"
              ></attachments>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CHECK_TEMPLATES } from '@/store/modules/App/actions';
import { GET_CONFIG, SET_CONFIG } from '@/store/modules/Config/actions';
import DistrictWideRates from './DistrictWideRates.vue';
import FuelPrices from './FuelPrices.vue';
import AdditionalInvoiceCosts from './AdditionalInvoiceCosts.vue';
import UploadFile from '@/components/UploadFile.vue';
import Attachments from '@/components/Attachments.vue';

export default {
  inject: ['eventHub'],
  components: { DistrictWideRates, FuelPrices, UploadFile, Attachments, AdditionalInvoiceCosts },
  data: () => ({
    tripEstimate: {
      display: true,
      showAdditionalInvoiceCost: false,
      substituteCost: 0,
      defaultRate: 0,
    },
    hasTemplate: false,
  }),
  computed: {
    ...mapGetters('user', ['me', 'users', 'usersById', 'canEditSettings']),
    ...mapGetters('tripType', ['tripTypes']),
    ...mapGetters('vehicleType', ['vehicleTypes']),
    ...mapGetters('config', ['districtWideRates']),
  },
  created() {
    this.eventHub.$on('saveTripEstimateConfig', () => this.save());
  },
  beforeDestroy() {
    this.eventHub.$off('saveTripEstimateConfig');
  },
  async mounted() {
    await this.fetchItems();
  },
  methods: {
    ...mapActions('app', [CHECK_TEMPLATES]),
    ...mapActions('config', [GET_CONFIG, SET_CONFIG]),
    async fetchItems() {
      this.trConfig = await this.getConfig('tripRequestForm');
      if (this.trConfig && this.trConfig.tripEstimate)
        this.tripEstimate = { ...this.tripEstimate, ...this.trConfig.tripEstimate };
    },
    async save() {
      try {
        const value = this.trConfig
          ? { ...this.trConfig, tripEstimate: this.tripEstimate }
          : { tripEstimate: this.tripEstimate };
        const r = await this.setConfig({ name: 'tripRequestForm', value });
        if (r && r.done) {
          this.$myalert.success('Trip Estimator settings saved');
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    handleOnFile(files) {
      this.hasTemplate = !!files.length;
    },
    onUpload() {
      this.$refs.attachments.fetchItems();
      this.refreshStore();
    },
    async refreshStore() {
      await this.checkTemplates();
    },
  },
};
</script>

<style scoped>
.save-btn {
  float: right;
  margin-bottom: 12px;
}
.bg-blue {
  background-color: aliceblue;
}
.inline {
  display: inline-block;
}
.text-field {
  max-width: 100px;
}
/* .checkbox-wrapper {
  display: flex;
  align-items: center !important;
}
.checkbox {
  margin-right: auto;
} */
.header-text {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
}
</style>
