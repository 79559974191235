<template>
  <v-list>
    <v-list-item-group>
      <v-list-item v-for="(item, i) in files" :key="i">
        <v-list-item-icon>
          <v-icon>mdi-file</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="ml-5">{{ item.filename }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <a target="_blank" :href="`/${client}/attachment/${item.id}/${encodeURIComponent(item.filename)}`">
            <v-icon color="primary">mdi-download</v-icon>
          </a>
        </v-list-item-action>
        <v-list-item-action>
          <v-icon color="error" @click="deleteAttachment(item)">mdi-delete</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_FILES, DELETE_FILE } from '@/store/modules/Attachment/actions';

export default {
  name: 'Attachments',
  inject: ['eventHub'],
  components: {},
  props: {
    recordType: String,
    recordId: Number,
    batchId: Number,
    readonly: Boolean,
  },
  data() {
    return {
      files: [],
    };
  },
  computed: {
    ...mapGetters('app', ['client']),
  },
  mounted() {
    if (this.recordType && this.recordId) this.fetchItems();
  },
  methods: {
    ...mapActions('attachment', [GET_FILES, DELETE_FILE]),
    async fetchItems() {
      let f = [];
      if (this.batchId) {
        const batchFiles = await this.getFiles({ type: 'triprequest', recordId: this.batchId });
        if (batchFiles.length) f = f.concat(batchFiles);
      }
      const recordFiles = await this.getFiles({ type: this.recordType, recordId: this.recordId });
      if (recordFiles.length) f = f.concat(recordFiles);
      this.files = f;
      this.$emit('onFiles', this.files);
    },
    async deleteAttachment(item) {
      if (!this.readonly) {
        const yes = await this.$myconfirm('Do you want to delete this file? This operation cannot be undone');
        if (yes) {
          await this.deleteFile({ id: item.id, filename: encodeURIComponent(item.filename) });
          this.$myalert.success('File deleted');
          this.fetchItems();
          this.$emit('onFileDelete');
        }
      }
    },
  },
  watch: {
    recordId(value) {
      if (value) this.fetchItems();
    },
  },
};
</script>

<style scoped></style>
